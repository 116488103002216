// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-aws-contact-js": () => import("./../../../src/pages/awsContact.js" /* webpackChunkName: "component---src-pages-aws-contact-js" */),
  "component---src-pages-aws-contact-submit-js": () => import("./../../../src/pages/awsContactSubmit.js" /* webpackChunkName: "component---src-pages-aws-contact-submit-js" */),
  "component---src-pages-aws-js": () => import("./../../../src/pages/aws.js" /* webpackChunkName: "component---src-pages-aws-js" */),
  "component---src-pages-br-error-js": () => import("./../../../src/pages/brError.js" /* webpackChunkName: "component---src-pages-br-error-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-submit-js": () => import("./../../../src/pages/contactSubmit.js" /* webpackChunkName: "component---src-pages-contact-submit-js" */),
  "component---src-pages-ecochange-js": () => import("./../../../src/pages/ecochange.js" /* webpackChunkName: "component---src-pages-ecochange-js" */),
  "component---src-pages-electric-payment-service-js": () => import("./../../../src/pages/electricPaymentService.js" /* webpackChunkName: "component---src-pages-electric-payment-service-js" */),
  "component---src-pages-groupcompany-js": () => import("./../../../src/pages/groupcompany.js" /* webpackChunkName: "component---src-pages-groupcompany-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kintia-js": () => import("./../../../src/pages/kintia.js" /* webpackChunkName: "component---src-pages-kintia-js" */),
  "component---src-pages-mcm-js": () => import("./../../../src/pages/mcm.js" /* webpackChunkName: "component---src-pages-mcm-js" */),
  "component---src-pages-office-js": () => import("./../../../src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recruit-application-js": () => import("./../../../src/pages/recruitApplication.js" /* webpackChunkName: "component---src-pages-recruit-application-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-recruit-submit-js": () => import("./../../../src/pages/recruitSubmit.js" /* webpackChunkName: "component---src-pages-recruit-submit-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-topmessage-js": () => import("./../../../src/pages/topmessage.js" /* webpackChunkName: "component---src-pages-topmessage-js" */)
}

